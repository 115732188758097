import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import OuvidoriaComponent from '@monorepo-amais/commons/components/ouvidoria/ouvidoria'

const Ouvidoria = ({ data }) => <OuvidoriaComponent Layout={Layout} temRegionais {...data}></OuvidoriaComponent>

export const query = graphql`
	query {
		cosmicjsInformacoesEstaticas(title: { eq: "Fale Conosco" }) {
			metadata {
				seo {
					descricao
					titulo
					imagem {
						imgix_url
						url
					}
				}
				ouvidoria_texto
				ouvidoria_titulo
				emails {
					email
				}
				assuntos {
					titulo_assunto
					email_assunto
				}
			}
		}
		regionais: cosmicjsInformacoesEstaticas(title: { eq: "Regionais" }) {
			metadata {
				telefones {
					estado
					telefone
					sigla
				}
			}
		}
	}
`

export default Ouvidoria
