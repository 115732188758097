import React, { useEffect, useState } from 'react'
import { graphql, navigate } from 'gatsby'
import styled, { css } from 'styled-components'
import MediaQuery from '@monorepo-amais/commons/components/mediaQuery'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import HeaderImage from '../../assets/img/ouvidoria.png'
import Select from 'react-select'
import { sendEmail } from '../../api/email'
import InputMask from 'react-input-mask'
import validator from 'validator'
import { useFormik } from 'formik'
import { Loading } from '../Search'
import isValidCPF from '../../utils/cpf'

import SEO from '../seo'
import { BreadcrumbAuto, MainContainer, Content } from '../Blocks'
import Mensagem from '../Mensagem/Mensagem'

import { Input, InputArea } from '../Inputs'
import { MainTitle } from '../Typography'
import theme from '../../theme'
import { isValidDate } from '../../utils/date'
import imgixUrlOptimizerAuto from '../../utils/imgixUrlOptimizerAuto'

const title = css`
	color: ${props => props.theme.colors.black_fonts};
	position: relative;
	&:after {
		content: '';
		position: absolute;
		bottom: -10px;
		left: 0;
		width: 90px;
		height: 5px;
		background: ${props => props.theme.colors.primary};
	}
`

const AboutTitle = styled.h1`
	${title}
	margin: 20px 20px 30px;
	font-size: 18px;
`

const ImageTop = styled.img`
	width: 100%;
	object-fit: contain;
	border-radius: 5px;
	// margin: 0px;
	margin: 30px 0 0 0;
`

const SelectLabel = styled.span`
	font-size: 12px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.67;
	letter-spacing: normal;
	text-align: left;
	color: ${props => props.theme.colors.grayb9};
	width: 100%;
	display: flex;
`

const Subject = styled.div`
	margin-top: 15px;
	margin-bottom: 20px;
	width: 100%;
	display: inline-block;
	@media (max-width: 1024px) {
		width: 100%;
	}
`

const ReturnDiv = styled.div`
	display: inline-block;
	margin: 30px 0px 0px 0px;
	width: 100%;
`

const FullName = styled.div`
	vertical-align: bottom;
	width: 100%;
	display: inline-block;
	margin-top: 10px;
`

const CPF = styled.div`
	margin-top: 40px;
	/* margin-right: 20px; */
`

/**
 * Componente do input com o tratamento de mascara para cpf
 * @param {object} cpfProps - objecto enviado pelo formik apos atualizacao
 */
const InputCpf = ({ value, error, onChange, onBlur, touched, required }) => (
	<InputMask
		mask='999.999.999-99'
		maskChar={null}
		alwaysShowMask={false}
		value={value}
		error={error && touched}
		onChange={onChange}
		onBlur={() => onBlur('cpf')}
	>
		{inputProps => (
			<Input
				id='cpf'
				value={value}
				error={error && touched}
				onChange={onChange}
				label={`CPF${!required ? ' (opcional)' : ''}`}
				required={required}
				lineHeight='15px'
				fontSize='16px'
				color='#b9b9b9'
				inputFontSize='14px'
				inputColor={props => props.theme.colors.gray66}
				inputPadding='padding: 0px 10px 11px 0px;'
				inputBorderBottom='1px solid #ddd'
				inputMarginTop='-34px'
				inputBackground='transparent'
				{...inputProps}
			/>
		)}
	</InputMask>
)

const BirthDate = styled.div`
	margin-top: 40px;
`

/**
 * Componente do input com o tratamento de mascara para data de nascimento
 * @param {object} birthDateProps - objecto enviado pelo formik apos atualizacao
 */
const InputBirthDate = ({ value, error, onChange, onBlur, touched, required }) => (
	<InputMask
		mask='99/99/9999'
		maskChar={null}
		alwaysShowMask={false}
		value={value}
		error={error && touched}
		onChange={onChange}
		onBlur={() => onBlur('birthDate')}
	>
		{inputProps => (
			<Input
				id='birthDate'
				value={value}
				error={error && touched}
				onChange={onChange}
				{...inputProps}
				label={`Data de Nascimento${!required ? ' (opcional)' : ''}`}
				required={required}
				lineHeight='15px'
				fontSize='16px'
				color='#b9b9b9'
				inputFontSize='14px'
				inputColor={props => props.theme.colors.gray66}
				inputPadding='padding: 0px 10px 11px 0px;'
				inputBorderBottom='1px solid #ddd'
				inputMarginTop='-34px'
				inputBackground='transparent'
			/>
		)}
	</InputMask>
)

const Message = styled.div`
	vertical-align: bottom;
	width: 100%;
	display: inline-block;
	margin-top: 40px;
`

const Phone = styled.div`
	// width: 32%;
	// display: inline-block;
	margin-top: 40px;
	margin-left: 20px;
	@media (max-width: 1024px) {
		// 	width: 100%;
		margin-right: 0px;
		margin-left: 0px;
	}
`

const InputPhone = ({ value, error, onChange, onBlur, touched }) => (
	<InputMask
		mask='(99) 99999-9999'
		maskChar={null}
		alwaysShowMask={false}
		value={value}
		error={error && touched}
		onChange={onChange}
		onBlur={() => onBlur('phone')}
	>
		{inputProps => (
			<Input
				id='phone'
				value={value}
				error={error && touched}
				onChange={onChange}
				{...inputProps}
				label='Telefone'
				required={true}
				lineHeight='15px'
				fontSize='16px'
				color='#b9b9b9'
				inputFontSize='14px'
				inputColor={theme.colors.gray66}
				inputPadding='padding: 0px 10px 11px 0px;'
				inputBorderBottom='1px solid #ddd'
				inputMarginTop='-34px'
				inputBackground='transparent'
			/>
		)}
	</InputMask>
)

const Record = styled.div`
	width: 100%;
	display: block;
	padding: 11px 0px 0px;
	margin-top: 39px;
	float: left;
	@media (max-width: 1024px) {
		width: 100%;
		padding: 0px;
		margin-left: 0px;
	}
`

const Email = styled.div`
	// width: 65%;
	// display: inline-block;
	margin-top: 40px;
	// @media (max-width: 1024px) {
	// 	width: 100%;
	// }
`

const Observation = styled.p`
	font-size: 16px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.25;
	letter-spacing: normal;
	text-align: left;
	color: ${theme.colors.gray66};
	margin-top: 31px;
	width: 100%;
	display: flex;
`

//TODO
const ButtonDiv = styled.div`
	margin-top: 30px;
	text-align: center;
`

const Button = styled.button`
	font-size: 16px;
	border-radius: 100px;
	width: 344px;
	height: 56px;
	border: none;
	background-color: ${theme.colors.primary};
	color: white;
	/* height: 26.8px; */
	padding: 0.5rem 1rem;
	margin: 0px 0px 30px;

	white-space: pre-wrap;

	&:not([disabled]):hover {
		background: #2eb0e4;
		border-color: #2eb0e4;
	}

	&:not([disabled]):active {
		background: #2eb0e4;
		border-color: #2eb0e4;
	}
`

const CustomDiv = styled(Content)`
	grid-template-areas: 'l l l l l l r r r r r r';

	@media (max-width: 991.98px) {
		grid-template-areas:
			'l l l l l l l l l l l l'
			'r r r r r r r r r r r r';
	}
`

const CustomFormLine = styled(Content)`
	grid-template-areas: 'l l l l l l l l r r r r';

	@media (max-width: 991.98px) {
		grid-template-areas:
			'l l l l l l l l l l l l'
			'r r r r r r r r r r r r';
	}
`

const About = styled.div`
	background-color: ${theme.colors.grayf8};
	padding: 0px;
	display: inline-block;
	float: left;
	height: 90%;
	@media (max-width: 1024px) {
		height: auto !important;
		width: 100%;
		margin: 0px;
	}
`

const AboutText = styled.div`
	font-size: 16px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.5;
	letter-spacing: normal;
	text-align: left;
	color: ${theme.colors.gray66};
	margin: 0px;
	margin: 0px 20px 0px;
`

const InvalidInput = styled.span`
	position: absolute;
	font-size: 12px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.67;
	letter-spacing: normal;
	text-align: left;
	color: #ff0000;
	margin: 5px 0px 0px;
`

const Required = styled.span`
	color: ${theme.colors.primary};
`

const Wrapper = styled.div`
	height: auto;
	padding: 0px 16px 24px 0;
	box-sizing: border-box;
	display: block;
	float: left;
`

const Item = styled.div`
	display: flex;
	align-items: center;
	height: 48px;
	position: relative;
`

const RadioButtonLabel = styled.label`
	position: absolute;
	top: 25%;
	left: 4px;
	width: 24px;
	height: 24px;
	border-radius: 50%;
	background: white;
	border: 1px solid #bebebe;
`

const RadioButtonText = styled.span`
	font-size: 20px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1;
	letter-spacing: normal;
	text-align: left;
	color: #6d6d6d;
`

const RadioButton = styled.input`
	opacity: 0;
	z-index: 1;
	border-radius: 50%;
	width: 24px;
	height: 24px;
	margin-right: 10px;
	&:hover ~ ${RadioButtonLabel} {
		background: white;
		&::after {
			content: '';
			display: block;
			border-radius: 50%;
			width: 16px;
			height: 16px;
			margin: 4px;
			background: ${theme.colors.grayb9};
		}
	}
	&:checked + ${RadioButtonLabel} {
		background: white;
		border: 1px solid ${theme.colors.grayb9};
		&::after {
			content: '';
			display: block;
			border-radius: 50%;
			width: 16px;
			height: 16px;
			margin: 4px;
			box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.1);
			background: ${theme.colors.grayb9};
		}
	}
`

const selectStyles = {
	indicatorSeparator: base => ({
		...base,
		background: 'none'
	}),
	dropdownIndicator: base => ({
		...base,
		svg: {
			fill: theme.colors.primary
		}
	}),
	control: base => ({
		...base,
		borderBotton: '1px',
		borderTop: '0px',
		borderLeft: '0px',
		borderRight: '0px',
		borderRadius: '0px',
		backgroundColor: theme.colors.white,
		borderColor: theme.colors.graye2,
		boxShadow: 'none',
		'&:hover': {
			borderColor: theme.colors.graye2
		}
	}),
	menu: base => ({
		...base,
		marginTop: 0,
		textAlign: 'center',
		wordWrap: 'break-word',
		overflow: 'hidden'
	}),
	menuList: base => ({
		...base,
		padding: 0
	}),
	singleValue: base => ({
		...base,
		color: theme.colors.gray66,
		fontSize: '20px',
		lineHeight: '19px'
	}),
	placeholder: base => ({
		...base,
		color: theme.colors.gray66,
		fontSize: '20px',
		lineHeight: '19px',
		left: '0px'
	}),
	option: (base, state) => ({
		...base,
		fontSize: '20px',
		lineHeight: '19px',
		backgroundColor: state.isSelected ? theme.colors.primary : theme.colors.white,
		color: state.isSelected ? theme.colors.white : theme.colors.gray66,
		':active': {
			backgroundColor: state.isSelected ? theme.colors.primary : theme.colors.white,
			color: state.isSelected ? theme.colors.white : theme.colors.gray66
		},
		'&:hover': {
			backgroundColor: theme.colors.primary,
			color: theme.colors.white
		}
	})
}

function hideMessage(setShowMessage, resetForm) {
	setShowMessage(false)

	resetForm()
}

let states = []

const OuvidoriaComponent = ({ Layout, cosmicjsInformacoesEstaticas, regionais, temRegionais }) => {
	const [loading, setLoading] = useState(false)
	const [firstLoad, setFirstLoad] = useState(false)
	const [showMessage, setShowMessage] = useState(false)
	const [messageContent, setMessageContent] = useState('')
	const [submitSuccess, setSubmitSuccess] = useState(false)

	// const emailPadrao = cosmicjsInformacoesEstaticas.metadata.email_padrao

	if (!firstLoad) {
		regionais.metadata.telefones.map(regiao => states.push({ value: regiao.sigla, label: regiao.estado }))
		setFirstLoad(true)
	}

	const formik = useFormik({
		initialValues: {
			name: '',
			cpf: '',
			birthDate: '',
			email: '',
			phone: '',
			record: '',
			subject: null,
			message: '',
			returnWay: 'email'
		},
		validate: values => {
			const err = {}
			const message = 'Campo obrigatório'
			const invalid = 'Campo inválido'
			const phoneCheck = values.phone
				.replace('(', '')
				.replace(')', '')
				.replace(' ', '')
				.replace('-', '')
			if (!values.name) err.name = message
			if (formik.values.subject !== null)
				if (formik.values.subject.label.substring(0, 9) === 'Ouvidoria') if (!values.cpf) err.cpf = message
			if (
				!isValidCPF(
					values.cpf
						.replace('.', '')
						.replace('.', '')
						.replace('-', '')
				) &&
				values.cpf
			)
				err.cpf = invalid

			if (formik.values.subject !== null)
				if (formik.values.subject.label.substring(0, 9) === 'Ouvidoria') if (!values.birthDate) err.birthDate = message

			if (!values.email) err.email = message
			if (!validator.isEmail(values.email) && values.email) err.email = invalid
			if (!phoneCheck) err.phone = message
			if (phoneCheck && phoneCheck.length < 10) err.phone = invalid
			//	if (!values.record) err.record = message
			if (!values.subject) err.subject = message
			if (!values.message) err.message = message
			if (!isValidDate(values.birthDate)) err.birthDate = invalid
			return err
		},
		onSubmit: values => {
			const formData = {
				name: values.name,
				cpf: values.cpf.replace(/[^0-9]+/g, ''),
				birthDate: values.birthDate,
				email: values.email,
				phone: values.phone,
				medicalRecordId: values.record || '',
				subject: values.subject.value.titulo_assunto,
				recipient: values.subject.value.email_assunto,
				message: values.message,
				preferencialContact: values.returnWay
			}
			setLoading(true)

			sendEmail('contact-us', formData)
				.then(response => {
					if (response.status === 202) {
						setSubmitSuccess(true)
						setMessageContent(
							'Sua reclamação, sugestão ou elogio foi enviado com sucesso! Em breve, você receberá o contato da a+ Medicina Diagnóstica'
						)
					}
					setLoading(false)
					setShowMessage(true)
				})
				.catch(err => {
					setSubmitSuccess(false)
					setMessageContent(
						'Infelizmente não conseguimos enviar sua mensagem agora, aguarde alguns instantes e tente novamente!'
					)
					setLoading(false)
					setShowMessage(true)
				})
		}
	})

	const handleBlur = field => {
		formik.setTouched({ ...formik.touched, [field]: true })
	}

	const cpfProps = {
		value: formik.values.cpf,
		error: formik.errors.cpf,
		onChange: formik.handleChange,
		onBlur: handleBlur,
		touched: formik.touched.cpf
	}

	const birthDateProps = {
		value: formik.values.birthDate,
		error: formik.errors.birthDate,
		onChange: formik.handleChange,
		onBlur: handleBlur,
		touched: formik.touched.birthDate
	}

	const phoneProps = {
		value: formik.values.phone,
		error: formik.errors.phone,
		onChange: formik.handleChange,
		onBlur: handleBlur,
		touched: formik.touched.phone
	}

	const subjects = cosmicjsInformacoesEstaticas.metadata.assuntos.map(assunto => ({
		value: assunto,
		label: assunto.titulo_assunto
	}))

	useEffect(() => {
		if (process.env.COSMIC_BUCKET !== 'amaispi') {
			navigate('/')
		}
	}, [])

	return (
		<Layout regionais={regionais}>
			<SEO
				title={cosmicjsInformacoesEstaticas.metadata.seo.titulo}
				description={cosmicjsInformacoesEstaticas.metadata.seo.descricao}
				image={imgixUrlOptimizerAuto(cosmicjsInformacoesEstaticas.metadata.seo.imagem.imgix_url)}
			/>

			{loading && <Loading />}

			<MainContainer>
				<MediaQuery minDeviceWidth={1224}>
					<BreadcrumbAuto title='Fale Conosco' />
				</MediaQuery>

				<MainTitle>Fale Conosco</MainTitle>

				<ImageTop src={HeaderImage} />

				<CustomDiv>
					<About className='left'>
						<AboutTitle
							dangerouslySetInnerHTML={{
								__html: cosmicjsInformacoesEstaticas.metadata.ouvidoria_titulo
							}}
						/>

						<AboutText
							dangerouslySetInnerHTML={{
								__html: cosmicjsInformacoesEstaticas.metadata.ouvidoria_texto
							}}
						/>
					</About>

					<form onSubmit={formik.handleSubmit} className='right'>
						<Subject>
							<SelectLabel>
								Assunto
								<Required>*</Required>
							</SelectLabel>

							<Select
								id='subject'
								styles={selectStyles}
								options={subjects}
								placeholder='Selecione'
								value={formik.values.subject}
								onChange={subject => formik.setFieldValue('subject', subject)}
								error={formik.touched.subject && formik.errors.subject}
								onBlur={() => handleBlur('subject')}
							/>

							{formik.touched.subject && formik.errors.subject && <InvalidInput>{formik.errors.subject}</InvalidInput>}
						</Subject>
						<FullName>
							<Input
								id='name'
								label='Nome'
								required={true}
								lineHeight='15px'
								fontSize='16px'
								color='#b9b9b9'
								inputFontSize='14px'
								inputColor={theme.colors.gray66}
								inputPadding='padding: 0px 10px 11px 0px;'
								inputBorderBottom='1px solid #ddd'
								inputMarginTop='-34px'
								inputBackground='transparent'
								onChange={formik.handleChange}
								value={formik.values.name}
								error={formik.touched.name && formik.errors.name}
								onBlur={() => handleBlur('name')}
							/>

							{formik.touched.name && formik.errors.name && <InvalidInput>{formik.errors.name}</InvalidInput>}
						</FullName>

						<CustomDiv>
							<CPF className='left'>
								<InputCpf
									{...cpfProps}
									required={
										formik.values.subject !== null && formik.values.subject.label.substring(0, 9) === 'Ouvidoria'
											? true
											: false
									}
								/>

								{formik.touched.cpf && formik.errors.cpf && <InvalidInput>{formik.errors.cpf}</InvalidInput>}
							</CPF>

							<BirthDate className='right'>
								<InputBirthDate
									{...birthDateProps}
									required={
										formik.values.subject !== null && formik.values.subject.label.substring(0, 9) === 'Ouvidoria'
											? true
											: false
									}
								/>

								{formik.touched.birthDate && formik.errors.birthDate && (
									<InvalidInput>{formik.errors.birthDate}</InvalidInput>
								)}
							</BirthDate>
						</CustomDiv>

						<CustomFormLine>
							<Email className='left'>
								<Input
									id='email'
									label='E-mail'
									required={true}
									lineHeight='15px'
									fontSize='16px'
									color='#b9b9b9'
									inputFontSize='14px'
									inputColor={theme.colors.gray66}
									inputPadding='padding: 0px 10px 11px 0px;'
									inputBorderBottom='1px solid #ddd'
									inputMarginTop='-34px'
									inputBackground='transparent'
									onChange={formik.handleChange}
									value={formik.values.email}
									error={formik.touched.email && formik.errors.email}
									onBlur={() => handleBlur('email')}
								/>

								{formik.touched.email && formik.errors.email && <InvalidInput>{formik.errors.email}</InvalidInput>}
							</Email>

							<Phone className='right'>
								<InputPhone {...phoneProps} />
								{formik.touched.phone && formik.errors.phone && <InvalidInput>{formik.errors.phone}</InvalidInput>}
							</Phone>
						</CustomFormLine>

						<Record>
							<Input
								required={false}
								id='record'
								label='Número da ficha'
								lineHeight='15px'
								fontSize='16px'
								color='#b9b9b9'
								inputFontSize='14px'
								inputColor={theme.colors.gray66}
								inputPadding='padding: 0px 10px 11px 0px;'
								inputBorderBottom='1px solid #ddd'
								inputMarginTop='-34px'
								inputBackground='transparent'
								inputType='int'
								maxLength='10'
								onChange={formik.handleChange}
								value={formik.values.record}
								onBlur={() => handleBlur('record')}
							/>
						</Record>

						<ReturnDiv>
							<SelectLabel>
								Retorno
								<Required>*</Required>
							</SelectLabel>

							<Wrapper>
								<Item>
									<RadioButton
										type='radio'
										name='radio'
										checked={formik.values.returnWay === 'email'}
										value={formik.values.returnWay}
										onChange={() => formik.setFieldValue('returnWay', 'email')}
									/>

									<RadioButtonLabel />

									<RadioButtonText>Por E-mail</RadioButtonText>
								</Item>
							</Wrapper>

							<Wrapper>
								<Item>
									<RadioButton
										type='radio'
										name='radio'
										checked={formik.values.returnWay === 'phone'}
										value={formik.values.returnWay}
										onChange={() => formik.setFieldValue('returnWay', 'phone')}
									/>

									<RadioButtonLabel />

									<RadioButtonText>Por Telefone</RadioButtonText>
								</Item>
							</Wrapper>
						</ReturnDiv>

						<Message>
							<InputArea
								id='message'
								label='Mensagem'
								required={true}
								lineHeight='15px'
								fontSize='16px'
								color='#b9b9b9'
								inputFontSize='14px'
								inputColor={theme.colors.gray66}
								inputPadding='padding: 0px 10px 11px 0px;'
								inputBorderBottom='1px solid #ddd'
								inputMarginTop='-34px'
								inputBackground='transparent'
								onChange={formik.handleChange}
								value={formik.values.message}
								error={formik.touched.message && formik.errors.message}
								onBlur={() => handleBlur('message')}
							/>

							{formik.touched.message && formik.errors.message && <InvalidInput>{formik.errors.message}</InvalidInput>}
						</Message>

						<Observation>
							<Required>*</Required> Campos Obrigatórios
						</Observation>

						<ButtonDiv>
							<Button type='submit'>Enviar</Button>
						</ButtonDiv>
					</form>
				</CustomDiv>
			</MainContainer>

			{showMessage && (
				<Mensagem
					closeMessage={() => hideMessage(setShowMessage, formik.resetForm)}
					textoBotao='OK'
					mensagem={messageContent}
					success={submitSuccess}
				/>
			)}
		</Layout>
	)
}

export const query = graphql`
	query {
		cosmicjsInformacoesEstaticas(title: { eq: "Ouvidoria" }) {
			metadata {
				seo {
					descricao
					titulo
					imagem {
						imgix_url
						url
					}
				}
				ouvidoria_texto
				ouvidoria_titulo
				emails {
					email
				}
			}
		}

		regionais: cosmicjsInformacoesEstaticas(title: { eq: "Regionais" }) {
			metadata {
				telefones {
					estado
					telefone
					sigla
				}
			}
		}
	}
`

export default OuvidoriaComponent
